.roll-info {
    width: 100%;
    color: var(--main-white);
    background-color: var(--main-black);
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    display: grid;
    grid-template-columns: 20% 60% 20%;
    align-items: center;
    white-space: nowrap;
    padding: 1.5rem 0rem;
    gap: 5px;
}

.roll-info:hover {
    cursor: pointer;
}

.roll-sub-menu {
    width: 100%;
    max-width: 100vw;
    height: fit-content;
    grid-column-start: span 3;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    /* position: fixed;
    right: 1rem; */
    /* top: 5rem; */
    background-color: var(--main-black);
    filter: drop-shadow(0 0 0px black);
    /* padding: 0rem 3rem; */
    /* margin-top: 20rem; */
}
.roll-sub-menu-close-button {
    width: fit-content !important;
    position: absolute;
    right: 0rem;
    top: 0rem;
    color: var(--main-red) !important;
    border-radius: 0;
}

.roll-sub-menu button {
    /* background-color: transparent;
    border: 0rem;
    color: var(--main-white);
    display: flex;
    align-items: center;
    margin: 0; */
    width: 50%;
    display: flex;
    justify-content: center;
    padding: 1rem;
    background-color: var(--main-grey);
    border: 0;
    border-radius: 1rem;
    margin: 1rem;
    /* border-top: 1px solid white;
    border-bottom: 1px solid white; */
    color: var(--main-white);
    font-weight: bolder;
}

.roll-sub-menu svg {
    width: fit-content !important;
}

.roll-info svg {
    width: 100%;
    margin: 1rem;
}

.display-info {
    width: 100%;
    padding: 0 1rem !important;
    display: flex;
    flex-direction: column;
    gap: 0 !important;
    border: none !important;
}

.toggle-delete-roll-container {
    display: none !important;
}

.toggle-delete-roll-container.active {
    background-color: var(--main-white);
    width: 100%;
    height: fit-content;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 40vh;
    left: 0;
    z-index: 2;
    padding: 1rem;
    color: black;
}

.toggle-delete-roll-container.active p {
    height: 2rem !important;
    color: black !important;
}

.toggle-delete-roll-container.active span { 
    display: flex;
}