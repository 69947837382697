.settings {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 3rem;
    padding-top: 3rem;
}

.settings button {
    width: 50%;
    font-size: 1.5rem;
    padding: 1rem;
    background-color: var(--main-grey);
    border: 0;
    border-radius: 1rem;
    color: var(--main-white);
    font-weight: bolder;
}

.danger-section {
    width: 100%;
    margin-bottom: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}
.danger-section button {
    border: 0;
    color: var(--main-red);
    background-color: rgb(104, 0, 0);
}

.danger-section h5 {
    color: var(--main-red);
}

.settings section {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
}