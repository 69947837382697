.add-photo {
    width: 100vw;
    height: fit-content;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
}

.add-photo form {
    width: 90%;
    max-width: 350px;
    height: fit-content;
    max-height: calc(100vh - 2rem);
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 2rem 0.5rem;
    padding-top: 5rem;
    margin-top: 2%;
    background-color: var(--main-black);
    filter: drop-shadow(0 0 15px black); 
    color: var(--main-white);
}

.add-photo svg {
    position: absolute;
    right: 2rem;
    top: 2rem;
    color: var(--main-red);
}

.add-photo select {
    width: 100%;
}

.flash-last-settings-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.flash-container, .last-settings-container {
    /* width: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

}
.flash-container, .last-settings-container input {
    width: fit-content !important;
}

.add-photo label {
    font-size: 2rem;
    text-align: left;
    width: fit-content;
}

.add-photo input {
    width: 100%;
    background-color: var(--main-black);
    color: var(--main-white);
    border-color: var(--main-orange);
    border-radius: 5px;
}

.add-photo input[type='text'] {
    font-size: 2rem;
    padding: 1rem 2rem;
}

.add-photo input[type='range'] {
    width: 100%;
}

.add-photo select {
    font-size: 2rem;
    background-color: var(--main-black);
    color: var(--main-white);
    border-color: var(--main-orange);
    border-radius: 5px !important;
}

.fieldset-container {
    width: 100%;
    display: flex;
}

.add-photo fieldset {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.5rem 0;
}

.select-lens {
    width: 90%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}

.select-shooting-mode {
    width: 90%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
}

.select-shooting-mode span {
    width: 50%;
}

.add-photo fieldset span {
    width: 100%;
    min-width: fit-content;
    height: fit-content;
    display: grid;
    grid-template-columns: 20% 80%;
    gap: 1rem;
    align-items: center;
    justify-content: left;
    border-bottom: 1px solid var(--main-white);
    padding: 0.5rem 0;
}

.add-photo fieldset input {
    height: 100%;
    width: 2rem;
}

.add-photo input[type='submit']  {
    font-size: 2rem;
    padding: 1rem;
    margin: 1rem 0;
    background-color: var(--main-orange);
    color: var(--main-black);
}

