.sign-up {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    gap: 1rem;
    font-size: 2rem;
}

.sign-up form {
    width: 90%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
    font-size: 2rem;
}

.sign-up input,
.sign-up button {
    background-color: var(--main-black);
    color: var(--main-white);
    width: 100%;
    font-size: 2rem;
    padding: 1rem;
    border: 0;
    border-bottom: 1px solid var(--main-white);
}

.sign-up button {
    /* margin: 2rem;
    border: 1px solid var(--main-white);
    text-decoration: underline; */
    width: 50%;
    font-size: 1.5rem;
    padding: 1rem;
    background-color: var(--main-grey);
    border: 0;
    border-radius: 1rem;
    color: var(--main-white);
    font-weight: bolder;
}

.error-message {
    color: var(--main-red);
}