.select-photo-master-container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 1rem; */
    /* box-shadow: 0 0 10px black; */
    color: var(--main-brown);
}

.select-photo-master-container h2 {
    width: 100%;
    padding: 2rem 0;
    background-color: var(--main-black);
    color: var(--main-white);
}

.add-photo-button {
    width: 7rem;
    height: 7rem;
    border-radius: 5rem;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: transparent;
    border: 3px solid var(--main-white);
    color: var(--main-white);
    position: fixed;
    bottom: 10rem;
    left: calc(50vw - 3.5rem);
}

.add-photo-button svg {
    color: var(--main-white);
}

.add-photo-button:hover {
    cursor: pointer;
}

.select-photo-master-container select {
    width: 100%;
    font-size: 2rem;
    padding: 0.5rem;
}

.mid-nav {
    height: 5.5rem;
    width: 100%;
    background-color: var(--main-black);
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.mid-nav svg {
    width: 100%;
    color: var(--main-white);
}

.mid-nav svg:hover {
    cursor: pointer;
}

.mid-nav svg:first-of-type,
.mid-nav svg:nth-of-type(2),
.mid-nav svg:nth-of-type(3) {
    border-right: 1px solid white;
}

.photo-modal-container.active {
    width: 100vw;
    height: 100vh;
    background-color: rgba(128, 128, 128, 0.281);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
}

.toggle-hidden {
    width: 100%;
    height: 4rem;
    background-color: var(--main-black);
    color: var(--main-white);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.photo-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 15rem;
}

.photo-container.grid {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
}

.photo-container.list {
    flex-wrap: wrap;
    justify-content: left;
}

.image-gallery-photo {
    height: 100vh;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.image-gallery-slide .image-gallery-description {
    position: static !important;
}

.image-gallery-description {
    background-color: var(--main-black) !important;
}