.edit-roll {
    display: none;
}

.edit-roll.active {
    width: 100vw;
    height: fit-content;
    background: rgba(128, 128, 128, 0.349);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
}

.edit-roll.active form {
    width: 90%;
    height: fit-content;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 2rem 0;
    position: absolute;
    top: 5%;
    left: 5%;
    margin-top: 5%;
    background-color: white;
    filter: drop-shadow(0 0 15px black);   
    color: var(--main-brown);
}

.edit-roll.active svg {
    position: absolute;
    right: 2rem;
    top: 2rem;
    color: var(--main-red);
    border-radius: 0;
}

.edit-roll.active label {
    font-size: 2rem;
}

.edit-roll.active input[type='text'] {
    font-size: 2rem;
    padding: 1rem 2rem;
}

.edit-roll.active input[type='submit']  {
    font-size: 2rem;
    padding: 1rem;
}