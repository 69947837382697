.home {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    padding-top: 3rem;
}

.home button {
    /* width: 80%;
    font-size: 1.5rem;
    padding: 1rem;
    border: 1px solid var(--main-white);
    background-color: transparent;
    color: var(--main-white); */
    width: 50%;
    font-size: 1.5rem;
    padding: 1rem;
    background-color: var(--main-grey);
    border: 0;
    border-radius: 1rem;
    color: var(--main-white);
    font-weight: bolder;
}
