body {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--main-black);
}

b {
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

select {
  border-radius: 0 !important;
  font-size: 1.5rem;
}

select::placeholder {
  color: var(--main-brown);
}

select:hover {
  cursor: pointer;
}

html {
  font-size: 8px;
}

button:hover {
  cursor: pointer;
}

#root {
  border-radius: 0;
  max-width: 100vw;
  overflow: hidden;
  background: var(--main-black);
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1, h2 {
  font-weight: lighter;
  letter-spacing: 0.3rem;
}

li {
  list-style: none;
}

select {
  background-color: var(--second-yellow);
  border: 2px solid var(--second-yellow);
}

option {
  max-width: 100vw;
  font-size: 1.5rem;
}

input {
  border: 1px solid var(--main-white);
}

:root {
  --main-orange: #E8850D;
  --main-blue: #085cd4;
  --main-yellow: #eeb822;
  --main-brown: #25190b;
  --main-red: rgb(230, 25, 25);
  --second-yellow: #fab617;
  --main-white: #d1d1d1;
  --main-black: rgb(14, 14, 14);
  --main-black2: rgba(14, 14, 14, 0.8);
  --main-grey: rgb(54, 54, 54);
  --test-background: #8B0000;
}

/*
--second-yellow: #fab617;
--main-brown: #25190b;
--main-red: rgb(230, 25, 25);
--main-black: rgb(14, 14, 14);

*/

hr {
  color: var(--main-brown);  
}

@font-face {
  font-family: 'Kodak';
  src: url('./fonts/kodakku.ttf');
}
@import "~react-image-gallery/styles/css/image-gallery.css";