.select-roll-master-container {
    width: 100%;
    height: fit-content;
    display: flex; 
    flex-direction: column;
    /* box-shadow: 0 0 1px var(--main-white); */
    color: var(--main-brown);
    margin-bottom: 10rem;
    background-color: transparent;
}

.add-camera-button {
    width: 7rem;
    height: 7rem;
    border-radius: 5rem;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    border: 3px solid var(--main-white);
    color: var(--main-white);
    position: fixed;
    bottom: 10rem;
    left: calc(50vw - 3.5rem);
}

.add-camera-button svg {
    color: var(--main-white) !important;
}

.add-camera-button:hover {
    cursor: pointer;
}

.select-roll {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
}

.select-roll h5 {
    width: 100%;
    text-align: left;
    /* border-bottom: 1px solid var(--main-white); */
}


.select-roll svg {
    color: var(--main-white);
}

.select-roll p {
    width: 80vw;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: var(--main-white);
}