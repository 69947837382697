.add-roll {
    width: 100vw;
    height: fit-content;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
}

.add-roll form {
    width: 90%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 2rem 0;
    margin-top: 5%;
    background-color: white;
    filter: drop-shadow(0 0 15px black);   
    color: var(--main-brown);
}

.add-roll select {
    width: 70%;
    font-size: 2rem;
    padding: 1rem 0.5rem;
    border: 1px solid rgb(179, 179, 179);
    border-radius: 1rem;
    background-color: transparent;
}

.add-roll svg {
    position: absolute;
    right: 2rem;
    top: 2rem;
    color: var(--main-red);
    border-radius: 0;
}

.add-roll label {
    font-size: 2rem;
}

.add-roll input[type='text'] {
    font-size: 2rem;
    padding: 1rem 2rem;
}

.add-roll input[type='submit']  {
    font-size: 2rem;
    padding: 1rem;
}