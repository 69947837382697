footer {
    width: 100%;
    max-width: 400px;
    position: fixed;
    bottom: 0;
    height: 10rem;
    background-color: var(--main-black);
    border-radius: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 5rem;
}

footer div {
    width: 100%;
    color: var(--main-white);
}


footer svg:hover {
    cursor: pointer;
}

footer div:nth-of-type(1),
.select-photo-icon, 
footer div:nth-of-type(3) {
    border-right: 2px solid var(--main-white);
    border-radius: 0;
}

footer .add-modal {
    position: fixed;
    width: fit-content;
    height: fit-content;
    padding: 2rem;
    border-radius: 1rem;
    bottom: 10rem;
    left: 46vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    background-color: black;
    z-index: 2;
}

footer .add-modal button {
    background-color: black;
    border: 0;
    border-bottom: 1px solid white;
    color: var(--main-white);
    font-size: 2rem;
}

footer div.active svg{
    border-bottom: 1px solid white;
}
