.camera-rolls-list {
    width: 100%;
    height: fit-content;
    padding: 0 ;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--main-black);
    overflow: hidden;
}


/* Active state: */
.active-div.active {
    /* position: relative; */
    height: fit-content;
    max-height: fit-content;
    display: flex;
    flex-direction: column;
    transition: max-height 1s;
}

/* Inactive state: */
.active-div {
    width: 100%;
    max-height: 0rem;
    transition: max-height 1s;
}


.camera-rolls-list ul {
    width: 100%;
    height: fit-content;
    transition: height 0.5s;
}

.camera-rolls-list ul.active {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.camera-rolls-list span {
    transition: 0.2s;
}
.camera-rolls-list span.active {
    transform: rotate(90deg);
}

.ellipse-container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid var(--main-white);
}

.add-buttons {
    display: none;
}

.add-buttons.active {
    width: 100%;
    display: flex;
}

.add-buttons button.inactive {
    display: none;
}

/* .add-buttons button:first-of-type {
    border-right: 1px solid white !important;
} */

.camera-rolls-list button {
    width: 50%;
    display: flex;
    justify-content: center;
    padding: 1rem;
    background-color: var(--main-grey);
    border: 0;
    border-radius: 1rem;
    margin: 1rem;
    /* border-top: 1px solid white;
    border-bottom: 1px solid white; */
    color: var(--main-white);
    font-weight: bolder;
}

.camera-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    width: 100%;
    background-color: var(--main-brown);
    /* background-color: var(--test-background); */
    color: var(--main-white);
    padding: 1rem;
    font-weight: bold;
    border-bottom: 1px solid grey;

    /* border-top: 1px solid var(--main-white);
    border-bottom: 1px solid var(--main-white); */
}

.camera-header h2 {
    letter-spacing: 0rem;
}

.camera-header:hover {
    cursor: pointer;
}

.loading {
    background-color: var(--main-brown);
    animation: loading 2s linear infinite alternate;;
}

@keyframes loading {
    0% {
        background-color: var(--main-brown);
    }
    50% {
        background-color: var(--main-grey);
    }
    100% {
        background-color: var(--main-brown);
    }
}