.App {
  width: 100vw;
  max-width: 400px;
  height: fit-content;
  min-height: 100vh;
  text-align: center;
  display: flex;
  /* grid-template-columns: 100vw;
  grid-template-rows: 15% 1fr 5%; */
  flex-direction: column;
  align-items: center;
  font-size: 2rem;
  overflow: hidden;
  color: var(--main-white);
  background: var(--main-black);
  border-radius: 0;
  padding-bottom: 6rem;
  /* font-family: 'Optima'; */
}


.add-camera-container.active,
.add-roll-container.active,
.add-lens-container.active,
.add-photo-container.active,
.edit-photo-container.active,
.edit-roll-container.active,
.add-modal-container.active,
.delete-photo-container.active,
.delete-roll-container.active {
  width: 100vw;
  height: 100vh;
  background-color: rgba(128, 128, 128, 0.281);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}