.photo-settings {
    width: 100%;
    height: fit-content;
    border: 1px solid rgba(161, 161, 161, 0.18);
    color: var(--main-brown);
    padding: 1rem;
}

.photo-settings p {
    width: 100%;
    text-align: center;
}

.photo-settings.scroll {
    padding: 0;
    color: var(--main-white);
}

.photo-settings.grid {
    font-weight: bolder;
    padding: 0.1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.photo-settings.grid img {
    object-fit: cover;
    min-height: 100%;
}

.photo-settings.grid p {
    width: 100%;
    text-align: center;
}

.scroll-photo-settings {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--main-white);
    padding-bottom: 1rem;
}

.scroll-photo-settings div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.scroll-photo-settings p,
.scroll-photo-settings h4 {
    font-weight: lighter !important;
}

.photo-settings-info {
    /* display: none; */
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    background-color: transparent;
    padding: 0;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
}

.photo-settings.grid h4 {
    width: 100%;
    font-size: 1.5rem;
}

.photo-settings.list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: transparent;
    padding: 0;
    font-weight: lighter;
    color: var(--main-white)
}

.photo-settings.list h3 {
    justify-content: flex-start;
    padding: 0;
    text-align: left;
}

.photo-settings.list p {
    font-weight: bolder;
    text-align: right;
}

.photo-settings.list div {
    width: 100%;
    display: flex;
    aspect-ratio: 1/0.07;
    text-align: center;
}

.photo-settings img {
    width: 100%;
}

.photo-settings.scroll div {
    aspect-ratio: 1/0.8;
    width: 100%;
    padding: 1rem;
    background-color: white;
    color: var(--main-brown);
}

.photo-settings h3 {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.photo-settings h4 {
    font-weight: bolder;
    width: 100%;
    display: flex;
    justify-content: center;
}
