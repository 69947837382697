.success-message {
    background-color: var(--main-white);
    color: black;
    width: 100%;
    height: fit-content;
    position: fixed;
    left: 0;
    top: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
}

.success-message b {
    color: green;
}