.edit-photo {
    display: none;
}

.edit-photo.active {
    width: 90%;
    max-width: 350px;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 2rem 0.5rem;
    position: fixed;
    top: 5%;
    left: 5%;
    background-color: var(--main-black);
    filter: drop-shadow(0 0 15px black); 
    color: var(--main-white);
    z-index: 2;
    overflow-y: scroll;
}

.edit-photo.active svg {
    position: absolute;
    right: 2rem;
    top: 2rem;
    color: red;
}

.edit-photo.active form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}

.edit-photo.active img {
    width: 100%;
}

.edit-photo.active label {
    font-size: 2rem;
}

.edit-photo.active input {
    width: 100%;
    background-color: var(--main-black);
    color: var(--main-white);
    border-color: var(--main-orange);
    border-radius: 5px;
}

.edit-photo.active input[type='text'] {
    font-size: 2rem;
    padding: 1rem 2rem;
}

.edit-photo.active input[type='range'] {
    width: 100%;
}

.edit-photo.active select {
    font-size: 2rem;
    background-color: var(--main-black);
    color: var(--main-white);
    border-color: var(--main-orange);
    border-radius: 5px !important;
}

.edit-photo fieldset {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.5rem 0;
}
.edit-photo fieldset span {
    width: 100%;
    min-width: fit-content;
    height: fit-content;
    display: grid;
    grid-template-columns: 20% 80%;
    gap: 1rem;
    align-items: center;
    justify-content: left;
    border-bottom: 1px solid var(--main-white);
    padding: 0.5rem 0;
}

.edit-photo.active input[type='submit']  {
    font-size: 2rem;
    padding: 1rem;
    margin: 1rem 0;
    background-color: var(--main-orange);
    color: var(--main-black);
}