.photo-modal {
    height: 0;
    width: 0;
    /* transition: 0.5s; */
}

.photo-modal div {
    display: none;
    width: 100%;
}

.photo-modal.active {
    width: 100%;
    max-width: 400px;
    min-height: 100vh;
    height: fit-content;
    background-color: var(--main-black);
    filter: drop-shadow(0 0 15px black); 
    color: var(--main-white);
    position: fixed;
    /* top: 0rem; */
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    
    top: 50%;
    /* left: 50%; */
    transform: translate(0%, -50%);
    transition: transform 0.3s ease;
}

.photo-modal-photo-settings {
    width: 100%;
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    padding: 1rem 0rem;
}

.photo-modal-photo-settings div {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    padding: 0rem 0.5rem;
}

.photo-modal-buttons svg {
    position: static !important;
    color: var(--main-white) !important;
}

.photo-modal.active div {
    display: block;
}

.photo-modal.active svg {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 100;
    color: var(--main-red);
}

.photo-modal-photo-container {
    min-width: 100vw;
    min-height: calc((100vw * 2)/3);
    padding: 0 !important;
}

.photo-modal.active img {
    width: 100%;
    object-fit: contain;
}

.image-upload-container {
    width: 100%;
    display: flex !important;
    justify-content: space-around;
}

.image-upload-container button {
    width: 50%;
    display: flex;
    justify-content: center;
    padding: 1rem;
    background-color: var(--main-grey);
    border: 0;
    border-radius: 1rem;
    margin: 1rem;
    color: var(--main-white);
    font-weight: bolder;
}

