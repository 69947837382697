.manage-roll {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 15rem;
    overflow-y: scroll;
}

.manage-roll select {
    width: 100%;
    font-size: 2rem;
    padding: 0.5rem;
}

.roll {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    color: var(--main-brown);
    border-radius: 3px;
}

.roll-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--main-brown);
    color: var(--main-white);
    padding: 1rem;
}

.roll ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.roll-photo-settings {
    width: 100%;
    height: fit-content;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--main-brown);
}


.photo-specs {
    /* width: 50% !important; */
    display: flex;
    justify-content: flex-start;
    gap: 3rem;
    align-items: center;
    flex-wrap: wrap;
    font-weight: lighter;
}

.photo-manage {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.roll-photo-settings div:first-of-type {
    display: flex;
    gap: 1rem;
    text-align: left;
}

.roll-photo-settings img {
    width: 100%;
}

.roll-photo-settings h3 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.roll-photo-settings h4 {
    font-weight: bolder;
    display: flex;
    justify-content: space-between;
}

.roll-photo-settings svg:first-of-type {
    color: green;
}
.roll-photo-settings svg:last-of-type {
    color: red;
}

.manage-roll button {
    margin: 2rem;
    padding: 1rem;
    font-size: 2rem;
    border: 0;
    border-radius: 5px;
    background-color: var(--main-blue);
    color: var(--main-white);
}

.toggle-delete-photo-container {
    display: none !important;
}

.toggle-delete-photo-container.active {
    background-color: var(--main-white);
    width: 100%;
    height: fit-content;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 40vh;
    left: 0;
    z-index: 2;
    padding: 1rem;
    color: black;
}