.delete-account {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    gap: 1rem;
    font-size: 2rem;
    background-color: rgba(128, 128, 128, 0.5);
    position: fixed;
    top: 0;
}

.delete-account form {
    width: 90%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
    font-size: 2rem;
    background-color: var(--main-black);
}

.delete-account input,
.delete-account button {
    background-color: var(--main-black);
    color: var(--main-white);
    width: 100%;
    font-size: 2rem;
    padding: 1rem;
    border: 0;
    border-bottom: 1px solid var(--main-white);
}

.delete-account button {
    margin: 2rem;
    border: 1px solid var(--main-white);
    text-decoration: underline;
}

.error-message {
    color: var(--main-red);
}

.delete-account svg {
    position: relative;
    left: 48%;
    bottom: 20%;
    color: var(--main-red);
    border-radius: 0;
}
