.add-lens {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
}

.add-lens form {
    width: 90%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 2rem 0;
    position: absolute;
    top: 5%;
    left: 5%;
    margin-top: 5%;
    background-color: white;
    filter: drop-shadow(0 0 15px black);   
    color: var(--main-brown);
}

.add-lens svg {
    position: absolute;
    right: 2rem;
    top: 2rem;
    color: var(--main-red);
    border-radius: 0;
}

.add-lens label {
    font-size: 2rem;
}

.add-lens input[type='text'] {
    width: 70%;
    font-size: 2rem;
    padding: 1rem 2rem;
}

.add-lens input[type='submit']  {
    font-size: 2rem;
    padding: 1rem;
}