header {
    width: 100%;
    display: flex;
    padding: 1rem 0;
    background-color: var(--second-yellow);
    box-shadow: 0 0 5px var(--main-brown);
}

header:hover {
    cursor: pointer;
}

header h1 {
    letter-spacing: 0.1rem;
    font-weight: 300;
    color: white;
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

header svg {
    color: var(--main-red);
}